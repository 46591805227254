import React, { useState } from "react";
import Unit from "../../models/Unit";
import Receipt from "../../models/Receipt";
import PrintUnitDetails from "../utils/PrintUnitDetails";
import { useReactToPrint } from "react-to-print";
import PrintUnitDetailsWrapper from "../utils/PrintUnitDetailsWrapper";
import EditReceiptConfirmationModal from "./EditReceiptConfirmationModal";
import classes from "./UnitDetailsModal.module.css";

interface UnitDetailsModalProps {
  show: boolean;
  onHide: () => void;
  unit: Unit;
}

const UnitDetailsModal: React.FC<UnitDetailsModalProps> = ({
  show,
  onHide,
  unit: initialUnit,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedReceipt, setSelectedReceipt] = useState<Receipt | null>(null);
  const [selectReceiptIndex, setSelectReceiptIndex] = useState<number | null>(
    null
  );
  const [unit, setUnit] = useState(initialUnit);

  const itemsPerPage = 5;

  const receiptList = unit.receipts || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentReceipts: Receipt[] = [];

  if (unit.receipts) {
    currentReceipts = unit.receipts.slice(indexOfFirstItem, indexOfLastItem);
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const printComponentRef = React.createRef<PrintUnitDetailsWrapper>();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current, // Pass the ref of the component to print
  });

  const handleEditReceiptClick = (receipt: Receipt, index: number) => {
    setSelectedReceipt(receipt);
    setSelectReceiptIndex(index);
  };

  const handleEditReceiptConfirmation = (
    data: Object,
    index: number | null
  ) => {
    console.log(data, index);

    if (index !== null) {
      // Create a copy of the unit object
      const updatedUnit = { ...unit };

      // Check if receipts array exists and is not undefined in updatedUnit
      if (updatedUnit.receipts && updatedUnit.receipts[index]) {
        // Modify the receipt at the specified index with the new data
        updatedUnit.receipts[index] = {
          ...updatedUnit.receipts[index],
          ...data, // Apply the new data
        };

        // Update the state with the modified unit object
        setUnit(updatedUnit);

        // Close the modal for editing the receipt
        setSelectedReceipt(null);
        setSelectReceiptIndex(null);
      }
    }
  };

  let unitType;

  switch (unit.type) {
    case "normal":
      unitType = "وحدة سكنية";
      break;
    case "commercial":
      unitType = "وحده تجاريه";
      break;
    case "special-commercial":
      unitType = "وحده تجاريه في دور سكني";
      break;
    default:
      unitType = "";
  }

  const totalPages = Math.ceil(receiptList.length / itemsPerPage);

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
          cursor: "pointer",
        }}
      />
      <div className="modal" tabIndex={-1} style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          {" "}
          {/* Add modal-lg class to make the modal larger */}
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">تفاصيل الوحدة</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onHide}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Display unit details here */}
              <p>رقم الوحدة: {unit.number}</p>
              <p>اسم المالك: {unit.owner}</p>
              <p>
                المصروفات الشهرية:{" "}
                {unit.monthlyExpense
                  ? Math.round(unit?.monthlyExpense.valueOf())?.toString()
                  : ""}
              </p>
              <p>المبنى: {unit.building.name}</p>
              <p>الساكن الحالى: {unit.resident}</p>
              <p>نوع الوحدة: {unitType}</p>

              <h5>الايصالات</h5>
              {unit.receipts ? (
                <>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>الشهر</th>
                        <th>السنة</th>
                        <th>المدفوعة</th>
                        <th>الوصف</th>
                        <th>المبلغ</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentReceipts.map(
                        (receipt: Receipt, index: number) => (
                          <tr key={index}>
                            <td>{receipt.month}</td>
                            <td>{receipt.year}</td>
                            <td>
                              {receipt.isPaid ? "نعم" : "لا"}
                              <button
                                onClick={() =>
                                  handleEditReceiptClick(receipt, index)
                                }
                                className="btn btn-success me-4"
                              >
                                Edit
                              </button>
                            </td>
                            <td>{receipt.description}</td>
                            <td>{receipt.amount.toFixed(2)}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => handlePrint()}
                              >
                                Print
                              </button>
                            </td>
                            <div className={classes.printOnly}>
                              <PrintUnitDetailsWrapper
                                ref={printComponentRef}
                                receipt={receipt}
                                unit={unit}
                              />
                            </div>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>

                  {/* Pagination */}
                  <nav aria-label="Receipts Pagination">
                    <ul className="pagination">
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </>
              ) : (
                <p>لا توجد ايصالات متاحة.</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                إغلاق
              </button>
            </div>
          </div>
        </div>
      </div>
      <EditReceiptConfirmationModal
        show={!!selectedReceipt}
        onHide={() => {
          setSelectedReceipt(null);
          setSelectReceiptIndex(null);
        }}
        onConfirm={handleEditReceiptConfirmation}
        selectedReceipt={selectedReceipt}
        selectedReceiptIndex={selectReceiptIndex}
        unit={unit}
      />
    </div>
  );
};

export default UnitDetailsModal;
