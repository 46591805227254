import { FormEvent, useEffect, useRef, useState } from "react";
import Plaza from "../models/Plaza";
import classes from "./Buildings.module.css";
import PlazasAccordion from "../components/accordion/PlazasAccordion";
import BuildingForm from "../components/modals/BuildingForm";
import PlazaForm from "../components/modals/PlazaForm";

const Plazas: React.FC = () => {
  const [plazas, setPlazas] = useState<Plaza[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedPlaza, setSelectedPlaza] = useState<Plaza | null>(null);

  useEffect(() => {
    const fetchPlazas = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/plazas`
      );

      const resposneData = await response.json();

      setPlazas(resposneData);
    };

    fetchPlazas();
  }, []);

  const handleEditPlaza = (plaza: Plaza) => {
    setSelectedPlaza(plaza); // Set the selected building
    openModal(); // Open the modal
  };

  const handleSavePlaza = (newPlaza: Plaza, editedPlaza: Plaza | null) => {
    if (editedPlaza) {
      // Edit case: Update the edited plaza in the array
      setPlazas((prevPlazas) =>
        prevPlazas.map((plaza) =>
          plaza._id === editedPlaza._id
            ? { ...plaza, name: newPlaza.name } // Update the name while keeping other properties unchanged
            : plaza
        )
      );
    } else {
      // Add case: Add the new plaza to the array
      setPlazas((prevPlazas) => [...prevPlazas, newPlaza]);
    }
    closeModal();
  };

  const handleDeletePlaza = async (plazaId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/plazas/${plazaId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: localStorage.getItem("auth_token") as string,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete plaza"); // Handle non-successful response
      }

      // Remove the plaza from the list by filtering it out
      setPlazas((prevPlazas) =>
        prevPlazas.filter((plaza) => plaza._id !== plazaId)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPlaza(null);
  };

  let plazasSection;

  if (plazas.length === 0) {
    plazasSection = (
      <div className="alert alert-info" role="alert">
        لا يوجد مناطق مضافة فى الوقت الحالى
        <br />
        يمكنك اضافة منطقة من الاعلى
      </div>
    );
  } else if (plazas.length > 0) {
    plazasSection = (
      <PlazasAccordion
        onEditPlaza={handleEditPlaza}
        plazas={plazas}
        onDeletePlaza={handleDeletePlaza}
      />
    );
  }

  return (
    <div dir="rtl" className={classes.Buildings__Page}>
      <div className="container">
        <h2>المناطق</h2>
        <hr />
        <button className="btn btn-primary" onClick={openModal}>
          Add Plaza
        </button>
        <section className={classes.Form__Section}></section>
        <hr />
        <section className={classes.Table__Section}>{plazasSection}</section>
      </div>
      <PlazaForm
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedPlaza={selectedPlaza}
        onSavePlaza={handleSavePlaza}
      />
    </div>
  );
};

export default Plazas;
