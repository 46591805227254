import React from "react";

interface EnglishToArabicConverterProps {
  number: number;
}

const EnglishToArabicConverter: React.FC<EnglishToArabicConverterProps> = ({
  number,
}) => {
  const englishToArabic: { [key: number]: string } = {
    0: "صفر",
    1: "واحد",
    2: "اثنان",
    3: "ثلاثة",
    4: "أربعة",
    5: "خمسة",
    6: "ستة",
    7: "سبعة",
    8: "ثمانية",
    9: "تسعة",
  };

  const arabicWords: { [key: number]: string } = {
    2: "عشرون",
    3: "ثلاثون",
    4: "أربعون",
    5: "خمسون",
    6: "ستون",
    7: "سبعون",
    8: "ثمانون",
    9: "تسعون",
  };

  const digits = number
    .toString()
    .split("")
    .map((digit) => parseInt(digit));
  const numDigits = digits.length;

  let arabicText = "";

  if (numDigits === 1) {
    arabicText = englishToArabic[digits[0]];
  } else if (numDigits === 2) {
    if (digits[0] === 1) {
      arabicText = englishToArabic[digits[1]] + " عشرة";
    } else {
      arabicText = arabicWords[digits[0]] + " و" + englishToArabic[digits[1]];
    }
  } else if (numDigits === 3) {
    arabicText = englishToArabic[digits[0]] + " مئة";
    if (digits[1] !== 0) {
      arabicText += " و" + arabicWords[digits[1]];
    }
    if (digits[2] !== 0) {
      arabicText += " و" + englishToArabic[digits[2]];
    }
  }

  return <p>{arabicText + " جنيه فقط لا غير"}</p>;
};

export default EnglishToArabicConverter;
