import React from "react";
import Unit from "../../models/Unit";
import Receipt from "../../models/Receipt";
import classes from "./PrintUnitDetails.module.css";
import EnglishToArabicConverter from "./EnglishToArabicConverter";
import Logo from "../../assets/imgs/logo.jpg";
import { formatNumber } from "../../lib/functions";

export interface PrintUnitDetailsProps {
  receipt: Receipt;
  unit: Unit;
}

const arabicMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

const PrintUnitDetails: React.FC<PrintUnitDetailsProps> = ({
  receipt,
  unit,
}) => {
  const arabicMonth = arabicMonths[parseInt(receipt.month) - 1];

  return (
    <div className={classes.Receipt}>
      <div className={classes.Gt__Logo}>
        <img src={Logo} alt="gt-logo" />
        <p className={classes.Serial__Number}>
          {formatNumber(receipt.serialNumber)}
        </p>
        <div></div>
      </div>
      <h2>ابراج جرين تاورز</h2>
      <h3>الابراج من (1 - 16)</h3>
      <div className={classes.Amount}>
        <label>جنيه</label>
        <p>{Math.ceil(receipt.amount)}</p>
      </div>
      <div className={classes.Field__Section}>
        <label>:ساهم السيد</label>
        <p>{unit.resident}</p>
      </div>
      <div className={classes.Field__Section}>
        <label>:بمبلغ وقدره فقط</label>
        <EnglishToArabicConverter number={Math.ceil(receipt.amount)} />
      </div>
      <div className={classes.Field__Section}>
        <p>وذلك مساهمة بأعمال الصيانة الشهرية عن شهر {arabicMonth}</p>
      </div>
      <div className={classes.Section}>
        <div className={classes.Field__Section}>
          <label>:برج</label>
          <p>{unit.building.name}</p>
        </div>
        <div className={classes.Field__Section}>
          <label>:طابق</label>
          <p>_______</p>
        </div>
        <div className={classes.Field__Section}>
          <label>:وحدة</label>
          <p>{unit.number}</p>
        </div>
      </div>

      <div className={classes.Footer}>
        <p>تحريرا فى {`1/${receipt.month}/${new Date().getFullYear()}`}</p>
        <div className={classes.Owners}>
          <p>اتحاد شاغلين ابراج جرين تاورز</p>
          <p>_____________</p>
        </div>
      </div>

      {/* Other unit details */}
      {/* ... */}
    </div>
  );
};

export default PrintUnitDetails;
