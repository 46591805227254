import React, { useRef, useState, FormEvent, useEffect } from "react";
import Plaza from "../../models/Plaza";
import classes from "./BuildingForm.module.css";

interface PlazaModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPlaza?: Plaza | null;
  onSavePlaza: (newPlaza: Plaza, editedPlaza: Plaza | null) => void;
}

const PlazaForm: React.FC<PlazaModalProps> = ({
  isOpen,
  onClose,
  selectedPlaza,
  onSavePlaza,
}) => {
  const plazaNameRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedPlaza && plazaNameRef.current) {
      // Add a null check
      plazaNameRef.current.value = selectedPlaza.name;
    } else if (plazaNameRef.current) {
      // Add a null check
      plazaNameRef.current.value = "";
    }
  }, [selectedPlaza]);

  if (!isOpen) {
    return null;
  }

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const plaza: Plaza = {
      name: plazaNameRef.current?.value || "",
    };

    try {
      let response;

      if (selectedPlaza) {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_IP}/plazas/${selectedPlaza._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("auth_token") as string,
            },
            body: JSON.stringify(plaza),
          }
        );
      } else {
        response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/plazas`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth_token") as string,
          },
          body: JSON.stringify(plaza),
        });
      }

      if (!response.ok) {
        throw new Error(
          selectedPlaza ? "Failed to update plaza" : "Failed to add plaza"
        );
      }

      const responseData = await response.json();
      const newPlaza: Plaza = {
        _id: responseData._id,
        name: plazaNameRef.current?.value || "",
      };
      onSavePlaza(newPlaza, selectedPlaza || null);
      plazaNameRef.current!.value = "";
      setError(null);
    } catch (error) {
      setError("Error adding building. Please try again."); // Set error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.Plaza__Form}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
          cursor: "pointer",
        }}
      />
      <div className="modal" tabIndex={-1} style={{ display: "block" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {error && (
                <div
                  className={`alert alert-danger ${classes.Alert__Danger}`}
                  role="alert"
                >
                  حدث خطأ
                </div>
              )}
              <form onSubmit={submitHandler}>
                <h3>اضافة منطقة</h3>
                <input
                  className="form-control"
                  type="text"
                  placeholder="اسم المنطقة"
                  ref={plazaNameRef}
                  defaultValue={selectedPlaza?.name || ""}
                />

                <button disabled={loading} className="btn btn-primary mt-4">
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlazaForm;
