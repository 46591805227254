import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Plaza from "../../models/Plaza";
import Building from "../../models/Building";
import classes from "./BuildingsAccordion.module.css";

interface AccordionItemProps {
  plaza: Plaza;
  onEditPlaza: (plaza: Plaza) => void;
  onDeletePlaza: (plazaId: string) => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  plaza,
  onEditPlaza,
  onDeletePlaza,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [buildingsPerPage] = useState(5);

  const handleEditButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEditPlaza(plaza);
  };

  const handleDeleteButtonClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (window.confirm("هل تريد مسح هذه المنطقة؟")) {
      if (plaza._id) {
        onDeletePlaza(plaza._id); // Invoke the onDeletePlaza function
      } else {
        console.error("Plaza ID is undefined");
      }
    } else {
      return false;
    }
  };

  const handleAccordionItemClick = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };

  const indexOfLastBuilding = currentPage * buildingsPerPage;
  const indexOfFirstBuilding = indexOfLastBuilding - buildingsPerPage;
  const currentBuildings = plaza.buildings
    ? plaza.buildings.slice(indexOfFirstBuilding, indexOfLastBuilding)
    : [];

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={`accordion-item ${isActive ? classes.Active : ""}`}>
      <h2 className="accordion-header" id={plaza._id}>
        <a
          className={`d-flex justify-content-between accordion-button ${
            isActive ? "collapsed" : ""
          }`}
          type="button"
          onClick={handleAccordionItemClick}
        >
          <span>{plaza.name}</span>

          <button
            className="btn btn-primary me-4"
            onClick={handleEditButtonClick}
          >
            Edit
          </button>
          <button
            onClick={handleDeleteButtonClick}
            className="btn btn-danger me-4"
          >
            Delete
          </button>
        </a>
      </h2>
      <div
        className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
        id={`building-collapse-${plaza._id}`}
        aria-labelledby={plaza._id}
        data-bs-parent="#buildings-accordion"
      >
        <div className="accordion-body">
          {plaza.buildings && plaza.buildings.length > 0 ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Building Name</th>
                  </tr>
                </thead>
                <tbody>
                  {currentBuildings.map((building: Building) => (
                    <tr key={building._id}>
                      <td>{building.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav>
                <ul className="pagination">
                  {Array.from({
                    length: Math.ceil(
                      (plaza.buildings ? plaza.buildings.length : 0) /
                        buildingsPerPage
                    ),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                        style={{ cursor: "pointer" }}
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </>
          ) : (
            <div className="alert alert-info" role="alert">
              لا يوجد مبانى مضافة لهذه المنطقة فى الوقت الحالى
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface PlazasAccordionProps {
  plazas: Plaza[];
  onEditPlaza: (plaza: Plaza) => void;
  onDeletePlaza: (plazaId: string) => void;
}

const PlazasAccordion: React.FC<PlazasAccordionProps> = ({
  plazas,
  onEditPlaza,
  onDeletePlaza,
}) => {
  return (
    <div>
      <div
        className={`accordion ${classes.Plaza__Accordion}`}
        id="plazas-accordion"
      >
        {plazas.map((plaza) => (
          <AccordionItem
            onEditPlaza={onEditPlaza}
            key={plaza._id}
            plaza={plaza}
            onDeletePlaza={onDeletePlaza}
          />
        ))}
      </div>
    </div>
  );
};

export default PlazasAccordion;
