import { useState } from "react";

import Apartment from "../../assets/imgs/logan_apartments.jpg";
import UnitDetailsModal from "../modals/UnitDetailsModal";
import Unit from "../../models/Unit";
import classes from "./UnitCard.module.css";

interface UnitCardProps {
  unit: Unit;
  onEdit?: (unit: Unit) => void;
  onDeleteUnit?: (unit: Unit) => void;
}

const UnitCard: React.FC<UnitCardProps> = ({ unit, onEdit, onDeleteUnit }) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const handleDetailsClick = () => {
    setShowDetailsModal(true);
  };

  const handleCloseModal = () => {
    setShowDetailsModal(false);
  };

  const handleDeleteButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (window.confirm("هل تريد مسح هذه الوحدة؟")) {
      if (unit._id) {
        onDeleteUnit?.(unit); // Invoke the onDeletePlaza function
      } else {
        console.error("Unit ID is undefined");
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {showDetailsModal && <div className={classes.Overlay} />}

      {/* Render the details modal */}
      {showDetailsModal && (
        <UnitDetailsModal
          show={showDetailsModal}
          onHide={handleCloseModal}
          unit={unit}
        />
      )}
      <div className={`card mb-4 ${classes.Unit__Card}`}>
        <div className={classes.Buttons__Sec}>
          {onEdit && (
            <button
              onClick={() => onEdit?.(unit)}
              className="btn btn-success ms-2"
            >
              Edit
            </button>
          )}
          <button onClick={handleDeleteButtonClick} className="btn btn-danger">
            Delete
          </button>
        </div>
        <img
          src={Apartment}
          className="card-img-top"
          style={{ height: "200px" }}
          alt="..."
        />
        <div className="card-body">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <div className="d-flex justify-content-between align-items-center">
                <span>رقم الوحدة</span>
                <span>{unit.number}</span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between align-items-center">
                <span>اسم المالك</span>
                <span>{unit.owner}</span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex justify-content-between align-items-center">
                <span>المصروفات الشهرية</span>
                <span>
                  {unit.monthlyExpense
                    ? Math.round(unit?.monthlyExpense.valueOf())
                        .toFixed(2)
                        ?.toString()
                    : ""}
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div className="card-footer">
          <button onClick={handleDetailsClick} className="btn btn-primary">
            عرض التفاصيل
          </button>
        </div>
      </div>
    </>
  );
};

export default UnitCard;
