import { FormEvent, useState } from "react";
import classes from "./Settings.module.css";

const SettingsPage = () => {
  const [serial, setSerial] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorSerial, setErrorSerial] = useState<string>("");
  const [showSuccessSerial, setShowSuccessSerial] = useState<boolean>(false);
  const [errorUser, setErrorUser] = useState<string>("");
  const [showSuccessUser, setShowSuccessUser] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const userRoleFromLocalStorage = localStorage.getItem("role");
  const isSuperAdmin = userRoleFromLocalStorage === "superadmin";

  const serialNumberSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      startingSerialNumber: serial,
    };

    try {
      const response = await fetch(
        "https://greentowerscompound.com/api/serial-numbers/set-starting-serial",
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error();
      }

      const responseData = await response.json();
      setShowSuccessSerial(true);
      setSerial("");
    } catch (err) {
      const errorMessage = "حدث خطأ";
      setErrorSerial(errorMessage);
      setShowSuccessSerial(false);
    } finally {
      setLoading(false);
    }
  };

  const addUserSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      username: username,
      password,
      role: userRole,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth_token") as string,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Registeration failed");
      }

      const responseData = await response.json();
      setShowSuccessUser(true);
      setUsername("");
      setPassword("");
      setErrorUser("");

      console.log("RES", responseData);
    } catch (error) {
      const errorMessage = "حدث خطأ";
      setErrorUser(errorMessage);
      setShowSuccessUser(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div dir="rtl" className={classes.Settings__Page}>
      <div className="container">
        <h2>الاعدادات</h2>
        <section>
          <h4>الرقم التسلسلى</h4>
          <div>
            <form className="d-flex" onSubmit={serialNumberSubmitHandler}>
              <input
                onChange={(e) => setSerial(e.target.value)}
                type="text"
                placeholder="الرقم التسلسلى"
                className="form-control"
                required
              />
              <button
                className="btn btn-primary me-4"
                disabled={loading}
                type="submit"
              >
                حفظ
              </button>
            </form>
            {showSuccessSerial && (
              <div className="alert alert-success mt-3 w-50" role="alert">
                تم تغيير الرقم التسلسلى
              </div>
            )}
            {errorSerial && (
              <div className="alert alert-danger mt-3 w-50" role="alert">
                {errorSerial}
              </div>
            )}
          </div>
        </section>
        {isSuperAdmin && (
          <section className="mt-4">
            <h4>اضافة مستخدم</h4>
            <div>
              <form onSubmit={addUserSubmitHandler}>
                <div className="row">
                  <div className="col-6">
                    <input
                      onChange={(e) => setUsername(e.target.value)}
                      type="text"
                      placeholder="اسم المستخدم"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="كلمة السر"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <div className="form-group">
                      <select
                        onChange={(e) => setUserRole(e.target.value)}
                        value={userRole}
                        className="form-control"
                        id="userRole"
                        required
                      >
                        <option value="">اختر الدور</option>
                        <option value="superadmin">Super Admin</option>
                        <option value="admin">Admin</option>
                        <option value="resident">Resident</option>
                      </select>
                    </div>
                  </div>
                </div>

                <button
                  className="btn btn-primary mt-4"
                  disabled={loading}
                  type="submit"
                >
                  حفظ
                </button>
              </form>
              {showSuccessUser && (
                <div className="alert alert-success mt-3 w-50" role="alert">
                  تم اصافة مستخدم جديد
                </div>
              )}
              {errorUser && (
                <div className="alert alert-danger mt-3 w-50" role="alert">
                  {errorUser}
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
