import { FormEvent, useEffect, useRef, useState } from "react";
import Building from "../models/Building";
import classes from "./Buildings.module.css";
import BuildingsAccordion from "../components/accordion/BuildingsAccordion";
import BuildingForm from "../components/modals/BuildingForm";
import Plaza from "../models/Plaza";

const Buildings: React.FC = () => {
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [plazas, setPlazas] = useState<Plaza[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(
    null
  );

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBuilding(null);
  };

  useEffect(() => {
    const fetchBuildings = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/buildings`
      );

      const resposneData = await response.json();

      setBuildings(resposneData);
    };

    const fetchPlazas = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/plazas`
      );

      const responseData = await response.json();

      setPlazas(responseData);
    };

    fetchBuildings();
    fetchPlazas();
  }, []);

  const handleEditBuilding = (building: Building) => {
    setSelectedBuilding(building); // Set the selected building
    openModal(); // Open the modal
  };

  const handleSaveBuilding = (
    newBuilding: Building,
    editedBuilding: Building | null
  ) => {
    if (editedBuilding) {
      // Edit case: Update the edited building in the array
      setBuildings((prevBuildings) =>
        prevBuildings.map((building) =>
          building._id === editedBuilding._id
            ? {
                ...building,
                name: newBuilding.name,
                plazaId: newBuilding.plaza,
              }
            : building
        )
      );
    } else {
      // Add case: Add the new building to the array
      setBuildings((prevBuildings) => [...prevBuildings, newBuilding]);
    }
    closeModal();
  };

  const handleDeleteBuilding = async (buildingId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/buildings/${buildingId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: localStorage.getItem("auth_token") as string,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete building"); // Handle non-successful response
      }

      // Remove the plaza from the list by filtering it out
      setBuildings((prevBuildings) =>
        prevBuildings.filter((building) => building._id !== buildingId)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatingBuilding = (building: Building) => {
    setBuildings((prevBuildings) => {
      // Find the index of the building to update in the prevBuildings array
      const index = prevBuildings.findIndex((b) => b._id === building._id);

      if (index !== -1) {
        // Create a new array with the updated building at the specified index
        const updatedBuildings = [...prevBuildings];
        updatedBuildings[index] = building;
        return updatedBuildings;
      }

      // If the building is not found, return the previous state
      return prevBuildings;
    });
  };

  let buildingSection;

  if (buildings.length === 0) {
    buildingSection = (
      <div className="alert alert-info" role="alert">
        لا يوجد ابراج مضافة فى الوقت الحالى
        <br />
        يمكنك اضافة برج من الاعلى
      </div>
    );
  } else if (buildings.length > 0) {
    buildingSection = (
      <BuildingsAccordion
        onEditBuilding={handleEditBuilding}
        buildings={buildings}
        onDeleteBuilding={handleDeleteBuilding}
        onUpdateBuilding={handleUpdatingBuilding}
      />
    );
  }

  return (
    <div dir="rtl" className={classes.Buildings__Page}>
      <div className="container">
        <h2>المبانى</h2>
        <hr />
        <button className="btn btn-primary" onClick={openModal}>
          Add Building
        </button>
        <section className={classes.Form__Section}></section>
        <hr />
        <section className={classes.Table__Section}>{buildingSection}</section>
      </div>
      <BuildingForm
        isOpen={isModalOpen}
        onClose={closeModal}
        onSaveBuilding={handleSaveBuilding}
        selectedBuilding={selectedBuilding}
        plazas={plazas}
      />
    </div>
  );
};

export default Buildings;
