export function formatNumber(number) {
  // Convert the number to a string
  if (number) {
    let numberStr = number.toString();

    // Calculate the number of zeros needed for padding
    let numZeros = 6 - numberStr.length;

    // Pad the string with zeros to the left
    let formattedNumber = "0".repeat(numZeros) + numberStr;

    return formattedNumber;
  }

  return "";
}
