import React, { Component } from "react";
import PrintBuildingReceipts, {
  PrintBuildingReceiptsProps,
} from "./PrintBuildingRecipts";

class PrintBuildingReceiptsWrapper extends Component<PrintBuildingReceiptsProps> {
  render() {
    return <PrintBuildingReceipts {...this.props} />;
  }
}

export default PrintBuildingReceiptsWrapper;
