import React, { Component } from "react";
import PrintUnitDetails, {
  PrintUnitDetailsProps,
} from "../utils/PrintUnitDetails";

class PrintUnitDetailsWrapper extends Component<PrintUnitDetailsProps> {
  render() {
    return <PrintUnitDetails {...this.props} />;
  }
}

export default PrintUnitDetailsWrapper;
