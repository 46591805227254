import { useEffect, useState } from "react";
import Building from "../models/Building";
import classes from "./Units.module.css";
import Unit from "../models/Unit";
import UnitCard from "../components/cards/UnitCard";
import UnitForm from "../components/modals/UnitForm";

const Units = () => {
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const [selectedBuildingId, setSelectedBuildingId] = useState<string | null>(
    "null"
  );

  const openUnitModal = () => {
    setIsUnitModalOpen(true);
  };

  const closeUnitModal = () => {
    setIsUnitModalOpen(false);
  };

  const addUnit = (newUnit: Unit) => {
    setUnits((prevUnits) => [...prevUnits, newUnit]);
    setSelectedBuildingId("null");
    setIsUnitModalOpen(false); // Close the UnitForm modal
  };

  useEffect(() => {
    const fetchBuildings = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/buildings`
      );

      const resposneData = await response.json();

      setBuildings(resposneData);
    };

    const fetchUnits = async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/units`);

      const resposneData = await response.json();

      setUnits(resposneData);
    };

    fetchBuildings();
    fetchUnits();
  }, []);

  const editUnitHandler = (unit: Unit) => {
    setSelectedUnit(unit);
    setIsUnitModalOpen(true);
  };

  const deleteUnitHandler = async (unit: Unit) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/units/${unit._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: localStorage.getItem("auth_token") as string,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete unit"); // Handle non-successful response
      }

      setUnits((prevUnits) => {
        return prevUnits.filter((prevUnit) => prevUnit._id !== unit._id);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateUnitHandler = (updatedUnit: Unit) => {
    console.log(updatedUnit);
    setUnits((prevUnits) => {
      // Find the index of the updated unit in the prevUnits array
      const index = prevUnits.findIndex((unit) => unit._id === updatedUnit._id);

      if (index !== -1) {
        // Create a new array with the updated unit at the specified index
        const updatedUnits = [...prevUnits];
        updatedUnits[index] = updatedUnit;
        return updatedUnits;
      }

      // If the unit is not found, return the previous state
      return prevUnits;
    });
    setIsUnitModalOpen(false);
  };

  let untisSection;

  if (units.length > 0) {
    const filteredUnits =
      selectedBuildingId !== "null"
        ? units.filter((unit) => unit.building._id === selectedBuildingId)
        : units;

    if (filteredUnits.length > 0) {
      untisSection = (
        <div id="units-section">
          <hr />
          <div className="row">
            {filteredUnits.map((unit) => {
              return (
                <div key={unit._id} className="col-3">
                  <UnitCard
                    onDeleteUnit={deleteUnitHandler}
                    onEdit={editUnitHandler}
                    unit={unit}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  } else {
    untisSection = (
      <div className="alert alert-info" role="alert">
        لا يوجد وحدات مضافة فى الوقت الحالى
        <br />
        يمكنك اضافة وحدة من الاعلى
      </div>
    );
  }

  return (
    <div dir="rtl" className={`${classes.Units__Page}  container`}>
      <h2>الوحدات</h2>
      <hr />
      <button className="btn btn-primary" onClick={openUnitModal}>
        Add Unit
      </button>
      <hr />
      <div className="row mb-2">
        <div className="col-4">
          <select
            value={selectedBuildingId || "null"}
            onChange={(e) => setSelectedBuildingId(e.target.value)}
          >
            <option value="null">اختر البناية</option>
            {buildings.map((b) => (
              <option key={b._id} value={b._id}>
                {b.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <section>{untisSection}</section>
      <UnitForm
        isOpen={isUnitModalOpen}
        onClose={closeUnitModal}
        onUnitUpdated={updateUnitHandler}
        onUnitAdded={addUnit}
        buildings={buildings}
        selectedUnit={selectedUnit}
      />
    </div>
  );
};

export default Units;
