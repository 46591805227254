import React, { useRef, useState, FormEvent, useEffect } from "react";
import Building from "../../models/Building";
import classes from "./BuildingForm.module.css";
import Plaza from "../../models/Plaza";

interface BuildingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveBuilding: (
    newBuilding: Building,
    editedBuilding: Building | null
  ) => void;
  selectedBuilding?: Building | null;
  plazas: Plaza[];
}

const BuildingForm: React.FC<BuildingModalProps> = ({
  isOpen,
  onClose,
  onSaveBuilding,
  selectedBuilding,
  plazas,
}) => {
  const buildingNameRef = useRef<HTMLInputElement>(null);
  const [selectedPlaza, setSelectedPlaza] = useState<string | null>(
    selectedBuilding ? selectedBuilding.plaza : "-1"
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedBuilding) {
      // Add a null check
      if (buildingNameRef.current) {
        buildingNameRef.current.value = selectedBuilding.name;
      }
      setSelectedPlaza(selectedBuilding.plaza);
    } else {
      // Add a null check
      if (buildingNameRef.current) {
        buildingNameRef.current.value = "";
      }
      setSelectedPlaza("-1");
    }
  }, [selectedBuilding]);

  if (!isOpen) {
    return null;
  }

  const handlePlazaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlaza(e.target.value);
  };

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const building: Building = {
      name: buildingNameRef.current?.value || "",
      plaza: selectedPlaza || "-1",
      units: [],
    };
    setTimeout(async () => {
      try {
        let response;

        if (selectedBuilding) {
          response = await fetch(
            `${process.env.REACT_APP_BACKEND_IP}/buildings/${selectedBuilding._id}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token") as string,
              },
              body: JSON.stringify(building),
            }
          );
        } else {
          response = await fetch(
            `${process.env.REACT_APP_BACKEND_IP}/buildings`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("auth_token") as string,
              },
              body: JSON.stringify(building),
            }
          );
        }

        if (!response.ok) {
          throw new Error("Failed to add building"); // Handle non-successful response
        }

        const responseData = await response.json();
        const newBuilding: Building = {
          _id: responseData._id,
          name: buildingNameRef.current?.value || "",
          plaza: selectedPlaza || "",
          units: [],
        };
        onSaveBuilding(newBuilding, selectedBuilding || null);
        buildingNameRef.current!.value = "";
        setSelectedPlaza("-1");
        setError(null);
      } catch (error) {
        setError("Error adding building. Please try again."); // Set error message
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  return (
    <div className={classes.Building__Form}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
          cursor: "pointer",
        }}
      />
      <div className="modal" tabIndex={-1} style={{ display: "block" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {error && (
                <div
                  className={`alert alert-danger ${classes.Alert__Danger}`}
                  role="alert"
                >
                  حدث خطأ
                </div>
              )}
              <form onSubmit={submitHandler}>
                <h3>اضافة مبنى</h3>
                <div className="row mb-2">
                  <div className="col-12">
                    <select
                      className="form-select"
                      value={selectedPlaza || "-1"}
                      onChange={handlePlazaChange}
                      disabled={!!selectedBuilding}
                    >
                      <option value="-1">اختر المنطقة</option>
                      {plazas.map((p) => {
                        return (
                          <option key={p._id} value={p._id}>
                            {p.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="اسم البرج"
                      ref={buildingNameRef}
                      defaultValue={selectedBuilding?.name || ""}
                    />
                  </div>
                </div>

                <button
                  disabled={loading}
                  className={`btn btn-primary mt-4 ${classes.Submit__Button}`}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingForm;
