import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Login.module.css";

const Login = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();

    const authData = {
      username: username.trim(),
      password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(authData),
        }
      );

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const responseData = await response.json();
      console.log("response data", responseData);
      localStorage.setItem("auth_token", responseData.token);
      localStorage.setItem("role", responseData.role);

      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      // Handle any network or request error here.
      console.error("Error:", error);
    }
  };

  return (
    <div className={classes.Login__Page}>
      <div className={classes.Login__FormContainer}>
        <form onSubmit={submitHandler} className={classes.Login__Form}>
          <h2>Login</h2>
          <div className={classes.Login__FormGroup}>
            <label htmlFor="username">Username</label>
            <input
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              type="text"
              id="username"
              name="username"
            />
          </div>
          <div className={classes.Login__FormGroup}>
            <label htmlFor="password">Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              id="password"
              name="password"
            />
          </div>
          <button className={classes.Btn__Black} type="submit">
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
