import React from "react";
import Receipt from "../../models/Receipt";
import Unit from "../../models/Unit";

interface EditReceiptConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: (data: Object, index: number | null) => void;
  selectedReceipt: Receipt | null;
  selectedReceiptIndex: number | null;
  unit: Unit | null;
}

const EditReceiptConfirmationModal: React.FC<
  EditReceiptConfirmationModalProps
> = ({
  show,
  onHide,
  onConfirm,
  selectedReceipt,
  selectedReceiptIndex,
  unit,
}) => {
  const paymentHandler = async (status: boolean) => {
    console.log(status);
    if (selectedReceipt && unit) {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/units/${unit._id}/receipts/${selectedReceiptIndex}`,
        {
          method: "PUT",
          body: JSON.stringify({
            ...selectedReceipt,
            isPaid: status,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth_token") as string,
          },
        }
      );

      if (!response.ok) {
        alert("Error updating receipt");
      }

      const responseData = await response.json();
      onConfirm(responseData, selectedReceiptIndex);
      onHide();
    }
  };

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="editReceiptConfirmationModalLabel"
      aria-hidden={!show}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editReceiptConfirmationModalLabel">
              تحصيل الايصال
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onHide}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>هل تم تحصيل هذا الايصال؟</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                paymentHandler(false);
              }}
            >
              لا
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                paymentHandler(true);
              }}
            >
              نعم
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReceiptConfirmationModal;
