import React from "react";
import classes from "./PrintUnitDetails.module.css";
import EnglishToArabicConverter from "./EnglishToArabicConverter";
import BuildingReceipt from "../../models/BuildingReceipt";
import Building from "../../models/Building";
import Logo from "../../assets/imgs/logo.jpg";
import { formatNumber } from "../../lib/functions";

export interface PrintBuildingReceiptsProps {
  buildingReceipts: BuildingReceipt[];
  building: Building;
}

const arabicMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

const PrintBuildingReceipts: React.FC<PrintBuildingReceiptsProps> = ({
  buildingReceipts,
  building,
}) => {
  return (
    <div className="row">
      {buildingReceipts.map((receipt, index) => {
        const arabicMonth = arabicMonths[parseInt(receipt.receipt.month) - 1];

        return (
          <div
            className={`col-12 ${index % 2 === 0 ? "" : classes.PageBreak}`}
            key={index}
          >
            <div className={classes.Receipt}>
              <div className={classes.Gt__Logo}>
                <img src={Logo} alt="gt-logo" />
                <p className={classes.Serial__Number}>
                  {formatNumber(receipt.receipt.serialNumber)}
                </p>
                <div></div>
              </div>
              <h2>ابراج جرين تاورز</h2>
              <h3>الابراج من (1 - 16)</h3>
              <div className={classes.Amount}>
                <label>جنيه</label>
                <p>{Math.ceil(receipt.receipt.amount)}</p>
              </div>
              <div className={classes.Field__Section}>
                <label>:ساهم السيد</label>
                <p>{receipt.unitResident}</p>
              </div>
              <div className={classes.Field__Section}>
                <label>:بمبلغ وقدره فقط</label>
                <EnglishToArabicConverter
                  number={Math.ceil(receipt.receipt.amount)}
                />
              </div>
              <div className={classes.Field__Section}>
                <p>وذلك مساهمة بأعمال الصيانة الشهرية عن شهر {arabicMonth}</p>
              </div>
              <div className={classes.Section}>
                <div className={classes.Field__Section}>
                  <label>:برج</label>
                  <p>{building.name}</p>
                </div>
                <div className={classes.Field__Section}>
                  <label>:طابق</label>
                  <p>_______</p>
                </div>
                <div className={classes.Field__Section}>
                  <label>:وحدة</label>
                  <p>{receipt.unitNumber}</p>
                </div>
              </div>

              <div className={classes.Footer}>
                <p>
                  تحريرا فى{" "}
                  {`1/${receipt.receipt.month}/${receipt.receipt.year}`}
                </p>
                <div className={classes.Owners}>
                  <p>اتحاد شاغلين ابراج جرين تاورز</p>
                  <p>_____________</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrintBuildingReceipts;
