import "./App.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Layout from "./components/layouts/Layout";
import Buildings from "./pages/Buildings";
import Units from "./pages/Units";
import Plazas from "./pages/Plazas";
import Login from "./pages/Login";
import SettingsPage from "./pages/Settings";

const isAuthenticated = () => {
  const token = localStorage.getItem("auth_token");
  return !!token;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: isAuthenticated() ? <Layout /> : <Navigate to="/login" />,
    caseSensitive: false,
    children: [
      {
        path: "",
        element: <Buildings />,
      },
      {
        path: "/units",
        element: <Units />,
      },
      {
        path: "/plazas",
        element: <Plazas />,
      },
      {
        path: "/building-units/:id",
        element: <Units />,
      },
      {
        path: "/settings",
        element: <SettingsPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
